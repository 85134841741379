'use client';

import { usePathname } from 'next/navigation';
// LoadingContext.tsx
import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import LoadingIcon from './LoadingIcon';
interface LoadingContextType {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);
export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};
interface LoadingProviderProps {
  children: ReactNode;
}
export const LoadingProvider: React.FC<LoadingProviderProps> = ({
  children
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return <LoadingContext.Provider value={{
    isLoading,
    setIsLoading
  }} data-sentry-element="unknown" data-sentry-component="LoadingProvider" data-sentry-source-file="LoadingProvider.tsx">
      {children}
      {isLoading && <div className='fixed inset-0 bg-black flex items-center justify-center z-50 top-0 right-0 bottom-0 left-0'>
          <div aria-label='Loading...' role='status' className='text-center'>
            <LoadingIcon />
            <p className='mt-4 text-xs'>Loading...</p>
          </div>
        </div>}
    </LoadingContext.Provider>;
};