import { buildVerboseLogger } from './verbose-logger';
import { buildProdClientLogger } from './prod-logger';
let logger;
const env = process.env.NODE_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV;
// When vercel_env is 'development', then it's local.  'preview' and 'production' means we're running on the Vercel platform.
const onVercel = (process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV || 'development').toLowerCase() !== 'development';
const isProduction = (process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV || '').toLowerCase() === 'production';
const isServer = typeof process !== 'undefined' && process.versions != null && process.versions.node != null;
// verbose logs on server or non-production envs
const useVerboseLogger = isServer || !isProduction;
const info = {
    env,
    node_env: process.env.NODE_ENV,
    vercel_env: process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV,
    isServer,
    useVerboseLogger,
    onVercel,
};
if (useVerboseLogger) {
    // loggingOnVercel will change some of the output format.
    logger = buildVerboseLogger(onVercel);
    logger.debug('VERBOSE LOGGING', info);
}
else {
    // We're on production client, so should only log info and above with no extra formatting
    logger = buildProdClientLogger();
    logger.info('PROD CLIENT LOGGING');
}
export default logger;
