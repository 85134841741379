'use client';

import type { FC, ReactNode } from 'react';
import React from 'react';
export const Provider: FC<{
  children: NonNullable<ReactNode>;
}> = ({
  children
}) => {
  return <>{children}</>;
};