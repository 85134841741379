'use client';

import { Button } from '../ui/button';
import { signOut } from '@/supabase/supabase-client';
import logger from '@monorepo/logger';
import { useRouter } from 'next/navigation';
import { useLoading } from '../LoadingProvider';
import { User } from '@monorepo/supabase-client-user';
import { refreshPageCache } from '@/actions';
import { useToast } from '@/hooks/useToast';
const log = logger.getLogger('Logout Button');
export const LogoutButton = ({
  user
}: {
  user: User | null;
}) => {
  const router = useRouter();
  const {
    setIsLoading
  } = useLoading();
  const {
    toast
  } = useToast();
  const logout = async () => {
    setIsLoading(true);
    try {
      await signOut();
      await refreshPageCache({
        path: '/',
        type: 'layout'
      });
      setTimeout(() => {
        void router.push('/login');
        return;
      }, 1000);
    } catch (e) {
      log.error(e);
      toast({
        description: 'Error signing out. Please try again. ',
        variant: 'destructive'
      });
    }
  };
  return user ? <Button variant='link' className='p-0 h-4 text-xs' onClick={logout} data-sentry-element="Button" data-sentry-component="LogoutButton" data-sentry-source-file="index.tsx">
      Logout
    </Button> : <></>;
};