import log from 'loglevel';
import prefix from 'loglevel-plugin-prefix';
// CLIENT
const colors = {
    reset: '\x1b[0m',
    bright: '\x1b[1m',
    dim: '\x1b[2m',
    underscore: '\x1b[4m',
    blink: '\x1b[5m',
    reverse: '\x1b[7m',
    hidden: '\x1b[8m',
    fg: {
        black: '\x1b[30m',
        red: '\x1b[31m',
        green: '\x1b[32m',
        yellow: '\x1b[33m',
        blue: '\x1b[34m',
        magenta: '\x1b[35m',
        cyan: '\x1b[36m',
        white: '\x1b[37m',
        gray: '\x1b[90m',
        crimson: '\x1b[38m', // Scarlet
    },
    bg: {
        black: '\x1b[40m',
        red: '\x1b[41m',
        green: '\x1b[42m',
        yellow: '\x1b[43m',
        blue: '\x1b[44m',
        magenta: '\x1b[45m',
        cyan: '\x1b[46m',
        white: '\x1b[47m',
        gray: '\x1b[100m',
        crimson: '\x1b[48m',
    },
};
const colorsOptions = {
    trace: colors.fg.magenta,
    debug: colors.fg.cyan,
    info: colors.fg.green,
    warn: colors.fg.yellow,
    error: colors.fg.red,
};
const text = (txt) => `${colorsOptions[txt.toLowerCase()]}${txt}${colors.reset}${colors.reset}`;
const levelFormatter = (level) => text(level.toUpperCase());
export const buildVerboseLogger = (onVercel) => {
    log.setDefaultLevel(log.levels.DEBUG);
    log.setLevel(log.levels.TRACE);
    prefix.reg(log);
    prefix.apply(log, {
        template: '[%t] %n %l:',
        timestampFormatter: (date) => date.toLocaleTimeString(),
        nameFormatter: (name) => name || 'global',
        levelFormatter: onVercel ? (level) => level.toUpperCase() : levelFormatter,
    });
    return log;
};
