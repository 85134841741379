'use client';

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import WebGL from 'three/addons/capabilities/WebGL.js';
const ParticleAnimation: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const sceneRef = useRef<{
    renderer?: THREE.WebGLRenderer;
    scene?: THREE.Scene;
    camera?: THREE.OrthographicCamera;
    particles?: THREE.Object3D;
  }>({});
  const mouseRef = useRef({
    x: 0,
    y: 0,
    moved: false
  });
  useEffect(() => {
    if (!canvasRef.current) return;
    let ww = window.innerWidth;
    let wh = window.innerHeight;
    const init = () => {
      if (!WebGL.isWebGL2Available()) {
        console.debug('WebGL2 not available.');
        const warning = WebGL.getWebGL2ErrorMessage();
        canvasRef.current?.parentNode?.appendChild(warning);
        return;
      }
      const renderer = new THREE.WebGLRenderer({
        canvas: canvasRef.current!
      });
      renderer.setSize(ww, wh);
      renderer.setClearColor(0x0c0a09, 1);
      const scene = new THREE.Scene();
      const camera = new THREE.OrthographicCamera(ww / -2, ww / 2, wh / 2, wh / -2, 0, 1000);
      camera.position.set(0, 0, 500);
      scene.add(camera);
      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.set(0, 250, 700);
      scene.add(light);
      const particles = new THREE.Object3D();
      scene.add(particles);
      sceneRef.current = {
        renderer,
        scene,
        camera,
        particles
      };
      renderer.render(scene, camera);
      animate();
    };
    const mouseMove = (e: MouseEvent | TouchEvent) => {
      const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
      const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
      mouseRef.current = {
        x: clientX - ww / 2,
        y: wh / 2 - clientY,
        moved: true
      };
    };
    const createParticle = () => {
      const geometry = new THREE.BufferGeometry();
      const vertices = new THREE.Vector3(mouseRef.current.x, mouseRef.current.y, -10);
      geometry.setAttribute('position', new THREE.Float32BufferAttribute([vertices.x, vertices.y, vertices.z], 3));
      const material = new THREE.PointsMaterial({
        color: 0xf7931a,
        size: 3,
        transparent: true,
        sizeAttenuation: false
      });
      const particle = new THREE.Points(geometry, material);
      (particle as any).speed = Math.random() / 100 - 0.008;
      (particle as any).direction = {
        x: (Math.random() - 0.5) * ww * 2,
        y: (Math.random() - 0.5) * wh * 2
      };
      sceneRef.current.particles?.add(particle);
    };
    const animate = () => {
      requestAnimationFrame(animate);
      if (mouseRef.current.moved) {
        createParticle();
        mouseRef.current.moved = false;
      }
      sceneRef.current.particles?.children.forEach((particle: any, index) => {
        const positions = particle.geometry.attributes.position.array;
        positions[0] += (particle.direction.x - positions[0]) * particle.speed;
        positions[1] += (particle.direction.y - positions[1]) * particle.speed;
        particle.geometry.attributes.position.needsUpdate = true;
        particle.material.opacity -= 0.005;
        if (particle.material.opacity < 0.05) {
          sceneRef.current.particles?.remove(particle);
        }
      });
      if (sceneRef.current.renderer && sceneRef.current.scene && sceneRef.current.camera) {
        sceneRef.current.renderer.render(sceneRef.current.scene, sceneRef.current.camera);
      }
    };
    const handleResize = () => {
      ww = window.innerWidth;
      wh = window.innerHeight;
      if (sceneRef.current.renderer && sceneRef.current.camera) {
        sceneRef.current.renderer.setSize(ww, wh);
        sceneRef.current.camera.left = ww / -2;
        sceneRef.current.camera.right = ww / 2;
        sceneRef.current.camera.top = wh / 2;
        sceneRef.current.camera.bottom = wh / -2;
        sceneRef.current.camera.updateProjectionMatrix();
      }
    };
    init();
    window.addEventListener('mousemove', mouseMove);
    window.addEventListener('touchmove', mouseMove);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('mousemove', mouseMove);
      window.removeEventListener('touchmove', mouseMove);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return <canvas ref={canvasRef} className='fixed -z-[1] top-0 right-0 bottom-0 left-0' data-sentry-component="ParticleAnimation" data-sentry-source-file="index.tsx" />;
};
export default ParticleAnimation;