import { createClient } from '@monorepo/supabase-client-user'
import logger from '@monorepo/logger'

const log = logger.getLogger('supabase-client')

const _supabaseClientUser = createClient()

export const getUser = async () => {
  log.debug('getUser')
  const db_result = await _supabaseClientUser.auth.getUser()
  return db_result
}

export const getSessionOrdAddress = async () => {
  const _getUser = await getUser()
  const ordWalletAddress = _getUser.data.user?.user_metadata.ord_wallet_address
  return ordWalletAddress
}

export const signOut = async () => {
  log.debug('signing user out')
  const { error } = await _supabaseClientUser.auth.signOut()

  if (error) {
    log.error(error)
  }

  return
}
